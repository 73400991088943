import {nextTick, onMounted} from "vue"
import { autorefreshBlockAdd, autorefreshBlockRemove } from "@/composables/useFetchState"

let ticketIdToHighlight: null | string | number

export function useHighlight() {

  const setTicketIdToHighlight = (ticketId) => ticketIdToHighlight = ticketId
  const getTicketIdToHighlight = () => ticketIdToHighlight
  const autorefreshBlockType = 'HighLightEffect'

  const highlightTicketOnBoard = async (ticketId) => {
    autorefreshBlockAdd(autorefreshBlockType, {ticketId})
    setTicketIdToHighlight(null)
    await nextTick()
    const waitTime = 200
    const effectDuration = 1700
    const ticketsContainer = document.querySelector('.tickets-wrap')
    const elementSelector = `.ticket[data-id="${ticketId}"]`
    const highlightCssClass = 'highlight'
    let element

    return new Promise((resolve) => {
      setTimeout(() => {
        element = ticketsContainer && ticketsContainer.querySelector(elementSelector)
        console.log('highlightTicketOnBoard', ticketsContainer, element, elementSelector)
        if (element) {
          element.classList.add(highlightCssClass)
          setTimeout(() => {
            element = ticketsContainer && ticketsContainer.querySelector(elementSelector)
            if (element) {
              element.classList.remove(highlightCssClass)
            }
            console.log('highlightTicketOnBoard resolve')
            autorefreshBlockRemove(autorefreshBlockType, {ticketId})
            resolve(true)
          }, effectDuration)
        } else {
          autorefreshBlockRemove(autorefreshBlockType, {ticketId})
          resolve(false)
        }
      }, waitTime)
    })
  }

  const highlightNote = async (noteId, scrollToNote = false) => {
    console.log('highlightNote', noteId, scrollToNote)
    if (!noteId) {
      return new Promise((resolve) => { resolve(false) })
    }

    autorefreshBlockAdd(autorefreshBlockType, {noteId})
    await nextTick()
    const waitTime = 200
    const scrollWaitTime = scrollToNote ? 700 : 0
    const effectDuration = 1700
    const notesContainer = document.querySelector('.right-panel-content')
    const elementSelector = `.message-item[data-id="${noteId}"]`
    const highlightCssClass = 'highlight'
    let element

    return new Promise((resolve) => {
      setTimeout(() => {
        element = notesContainer && notesContainer.querySelector(elementSelector)
        if (!element) {
          resolve(false)
          return
        }
        if (scrollToNote) {
          element.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
          })
        }
        setTimeout(() => {
          if (element) {
            element.classList.add(highlightCssClass)
            setTimeout(() => {
              element = notesContainer && notesContainer.querySelector(elementSelector)
              if (element) {
                element.classList.remove(highlightCssClass)
              }
              console.log('highlightNote resolve')
              autorefreshBlockRemove(autorefreshBlockType, {noteId})
              resolve(true)
            }, effectDuration)
          } else {
            autorefreshBlockRemove(autorefreshBlockType, {noteId})
            resolve(false)
          }
        }, scrollWaitTime)
        console.log('highlightNote', notesContainer, element, elementSelector)
      }, waitTime)
    })
  }

  const highlightAutomationRule = async (ruleId, scrollToRule = false) => {
    console.log('highlightAutomationRule', ruleId, scrollToRule)
    if (!ruleId) {
      return new Promise((resolve) => { resolve(false) })
    }

    //autorefreshBlockAdd(autorefreshBlockType, {ruleId})
    //await nextTick()
    const waitTime = 0
    const scrollWaitTime = scrollToRule ? 100 : 0
    const effectDuration = 1700
    const rulesContainer = document.querySelector('.automator-rules-container')
    const elementSelector = `.rule-wrap[data-id="${ruleId}"]`
    const highlightCssClass = 'highlight'
    let element

    return new Promise((resolve) => {
      setTimeout(() => {
        element = rulesContainer && rulesContainer.querySelector(elementSelector)
        if (!element) {
          resolve(false)
          return
        }
        if (scrollToRule) {
          element.scrollIntoView({
            block: "nearest",
            inline: "nearest",
            behavior: "smooth",
          })
        }
        setTimeout(() => {
          if (element) {
            if (element.classList.contains(highlightCssClass)) {
              resolve(true)
              return
            }
            element.classList.add(highlightCssClass)
            setTimeout(() => {
              element = rulesContainer && rulesContainer.querySelector(elementSelector)
              if (element) {
                element.classList.remove(highlightCssClass)
              }
              console.log('highlightNote resolve')
              //autorefreshBlockRemove(autorefreshBlockType, {ruleId})
              resolve(true)
            }, effectDuration)
          } else {
            //autorefreshBlockRemove(autorefreshBlockType, {ruleId})
            resolve(false)
          }
        }, scrollWaitTime)
        console.log('highlightAutomationRule', rulesContainer, element, elementSelector)
      }, waitTime)
    })
  }

  const highlightTeamRole = async (roleId, scrollToRole = false) => {
    console.log('highlightTeamRole', roleId, scrollToRole)
    if (!roleId) {
      return new Promise((resolve) => { resolve(false) })
    }

    //autorefreshBlockAdd(autorefreshBlockType, {ruleId})
    //await nextTick()
    const waitTime = 0
    const scrollWaitTime = scrollToRole ? 100 : 0
    const effectDuration = 1700
    const rolesContainer = document.querySelector('.project-team-wrap')
    const elementSelector = `.role-wrap[data-id="${roleId}"]`
    const highlightCssClass = 'highlight'
    let element

    return new Promise((resolve) => {
      setTimeout(() => {
        element = rolesContainer && rolesContainer.querySelector(elementSelector)
        if (!element) {
          resolve(false)
          return
        }
        if (scrollToRole) {
          element.scrollIntoView({
            block: "nearest",
            inline: "nearest",
            behavior: "smooth",
          })
        }
        setTimeout(() => {
          if (element) {
            if (element.classList.contains(highlightCssClass)) {
              resolve(true)
              return
            }
            element.classList.add(highlightCssClass)
            setTimeout(() => {
              element = rolesContainer && rolesContainer.querySelector(elementSelector)
              if (element) {
                element.classList.remove(highlightCssClass)
              }
              console.log('highlightTeamRole resolve')
              //autorefreshBlockRemove(autorefreshBlockType, {ruleId})
              resolve(true)
            }, effectDuration)
          } else {
            //autorefreshBlockRemove(autorefreshBlockType, {ruleId})
            resolve(false)
          }
        }, scrollWaitTime)
        console.log('highlightTeamRole', rolesContainer, element, elementSelector)
      }, waitTime)
    })
  }

  return {
    getTicketIdToHighlight,
    setTicketIdToHighlight,
    highlightTicketOnBoard,
    highlightNote,
    highlightAutomationRule,
    highlightTeamRole,
  }

}
