import {computed, defineComponent, PropType, ref} from "vue"
import {useI18n} from "vue-i18n"
import {IData} from "@/types"
import UserAvatar from "@/components/UserAvatar"
import NoteEditForm from "@/components/NoteEditForm"
import SvgIcon from "@/components/SvgIcon"
import {useFancybox} from "@/composables/useFancybox"
import {useStore} from "vuex"
import TicketService from "@/services/ticket_service"

export default defineComponent({
  name: 'NotesList',
  props: {
    notes: {
      type: Array as PropType<IData[]>,
      default: () => [],
    },
    ticketCover: {
      type: String,
      required: false,
    },
    editNoteId: {
      type: String,
      required: false,
    },
    replyNoteId: {
      type: String,
      required: false,
    },
    replyPositionNoteId: {
      type: String,
      required: false,
    },
    editNoteObj: {
      type: Object as PropType<IData>,
      required: false,
    },
    replyNoteObj: {
      type: Object as PropType<IData>,
      required: false,
    },
    activeMenuNoteId: {
      type: String,
      required: false,
    },
    ticketCoverEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    noteActionsEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
},
  emits: [
    //'editNote',
    'replyNote',
    //'deleteNote',
    'cancelNote',
    'noteBeforeSave',
    'noteSaved',
    'noteFormHasChanges',
    'changeUploadActive',
    'makeCover',
    'removeCover',
    'noteMenu',
    'toggleTextShortMode',
  ],

  components: {
    UserAvatar,
    NoteEditForm,
    SvgIcon,
  },

  setup(props, {emit}) { //props, context

    const { t } = useI18n()
    const store = useStore()
    // const ticketCoverEnabled = computed<boolean>(() => store.getters['settings/show_cover'])
    // const editNoteForm = ref<InstanceType<typeof NoteEditForm> | null>(null)
    // const replyNoteForm = ref<InstanceType<typeof NoteEditForm> | null>(null)

    // const editNote = (options: IData) => {
    //   console.log('NotesList editNote', options)
    //   emit('editNote', options)
    // }

    const replyNote = (noteId: string, ticketId: string) => {
      emit('replyNote', noteId, ticketId)
    }

    // const deleteNote = (options: IData) => {
    //   emit('deleteNote', options)
    // }

    const cancelNote = (options: IData) => {
      emit('cancelNote', options)
    }

    const noteBeforeSave = (options: IData) => {
      emit('noteBeforeSave', options)
    }

    const noteSaved = (options: IData) => {
      emit('noteSaved', options)
    }

    const noteFormHasChanges = (isChanged: boolean, formType: string) => {
      emit('noteFormHasChanges', isChanged, formType)
    }

    const changeUploadActive = (isChanged: boolean, formType: string) => {
      emit('changeUploadActive', isChanged, formType)
    }

    const { openFancybox } = useFancybox()

    const makeCover = async (ticketId, attachmentId) => {
      emit('makeCover', ticketId, attachmentId)
    }

    const removeCover = async (ticketId) => {
      emit('removeCover', ticketId)
    }

    const triggerNoteMenu = async (event, note) => {
      emit('noteMenu', {note, targetElement: event.target.closest('button')})
    }

    //const loadingNoteText = ref<string | null>(null)
    const toggleTextShortMode = noteId => {
      noteId = noteId.toString()
      //loadingNoteText.value = noteId
      emit('toggleTextShortMode', noteId)
    }

    const isNoComments = note => {
      return !(note?.files?.videoList?.length || note?.files?.imageList?.length || note?.files?.fileList?.length || note.text || note.short_text)
    }

    // const isUploadInProgress = () => {
    //   console.log('NotesList isUploadInProgress', editNoteForm.value, editNoteForm.value && editNoteForm.value.isUploadInProgress(), replyNoteForm.value, replyNoteForm.value && replyNoteForm.value.isUploadInProgress())
    //   return (editNoteForm.value && editNoteForm.value.isUploadInProgress()) || (replyNoteForm.value && replyNoteForm.value.isUploadInProgress())
    // }

    return {
      t,
      // ticketCoverEnabled,
      //editNote,
      replyNote,
      //deleteNote,
      cancelNote,
      noteSaved,
      noteBeforeSave,
      openFancybox,
      noteFormHasChanges,
      changeUploadActive,
      makeCover,
      removeCover,
      triggerNoteMenu,
      toggleTextShortMode,
      //loadingNoteText,
      isNoComments,
      // isUploadInProgress,
    }
  }

})
