import TicketService from "@/services/ticket_service"
import {NavigationFailure, useRouter} from "vue-router"
import {IApiResponse} from "@/types"
import BoardService from "@/services/board_service";
import {useAppUtils} from "@/composables/useAppUtils"
import {useI18n} from "vue-i18n";
import {useLocale} from "@/composables/useLocale";

export function useTicketActions() {

  const router = useRouter()

  const {t} = useI18n();
  const {f} = useLocale();
  const { vConfirm } = useAppUtils()

  const archiveTicket: (ticketId: string) => Promise<IApiResponse> = async function (ticketId: string): Promise<IApiResponse> {
    return await TicketService.archiveTicket(ticketId)
  }

  const cloneTicket: (ticketId: string) => Promise<NavigationFailure | void | undefined> = async function (ticketId: string): Promise<NavigationFailure | void | undefined> {
    return await router.push({
      name: 'Ticket',
      params: {id: 0},
      query: {clone: ticketId}
    })
  }

  const removeTicket: (ticketId: string, ticketName: string) => Promise<IApiResponse | false> = async function (ticketId: string, ticketName: string): Promise<IApiResponse | false> {
    if (! await vConfirm(f('Are you sure you want to delete the ticket "%s"?', `<b>${ticketName}</b>`))) {
      return false
    }
    return await TicketService.removeTicket(ticketId)
  }

  const quickAddTicket: (projectId:string, stepId:string, name: string, coverFile?) => Promise<any> = async function (projectId:string, stepId:string, name: string, coverFile?) {
    return await BoardService.quick_save_ticket(projectId, stepId, name, coverFile);
  }

  const assignTicketToMe: (ticketId: string) => Promise<IApiResponse> = async function (ticketId: string): Promise<IApiResponse> {
    return await TicketService.assignTicketToMe(ticketId)
  }

  const moveTicketToNextStage: (ticketId: string) => Promise<IApiResponse> = async function (ticketId: string): Promise<IApiResponse> {
    return await TicketService.moveTicketToNextStage(ticketId)
  }

  return {
    archiveTicket,
    cloneTicket,
    removeTicket,
    quickAddTicket,
    assignTicketToMe,
    moveTicketToNextStage,
  }

}
