import {computed, defineComponent, onBeforeUnmount, ref, watch, nextTick, inject, onMounted} from "vue"
import {useI18n} from "vue-i18n"
import {IApiResponse, IData, INote} from "@/types"
import UserAvatar from "@/components/UserAvatar"
import NoteEditForm from "@/components/NoteEditForm"
import NotesList from "@/components/NotesList"
import NotesService from "@/services/notes_service"
import {useStore} from "vuex"
import {onBeforeRouteLeave, onBeforeRouteUpdate} from "vue-router"
import {refreshScreen, useFetchState} from "@/composables/useFetchState"
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TicketService from "@/services/ticket_service"
//import {useScroll} from "@vueuse/core"
import TicketTasks from '@/components/TicketTasks.vue'
import {useAppUtils} from "@/composables/useAppUtils"
import _cloneDeep from "lodash/cloneDeep"
import MenuNote from "@/components/Menus/MenuNote.vue"
import {useFloatingExists} from "@/composables/useFloatingExists"
import { autorefreshBlockAdd, autorefreshBlockRemove, autorefreshBlockExists } from "@/composables/useFetchState"
import _debounce from 'lodash/debounce'
import ApiService from "@/services/api_service"
import {useNoteDraft} from "@/composables/useNoteDraft"
import {useHighlight} from "@/composables/useHighlight"

export default defineComponent({
  name: 'NotesPanel',
  props: {
    screen: {
      type: String,
      validator(value: string) {
        // Значение должно соответствовать одной из этих строк
        return ['ticket', 'dashboard', 'listview'].includes(value)
      },
      required: true,
    },
    ticket: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    //'eventRestored',
    'ticketTasksCountChanged',
    'gotoTicket',
    'noteSaved',
  ],

  components: {
    UserAvatar,
    NoteEditForm,
    NotesList,
    SvgIcon,
    TicketTasks,
    MenuNote,
  },

  setup(props, {emit}) { //props, context

    console.log('NotesPanel setup', props, ref(props['screen']))

    const { t } = useI18n()

    const screen = ref(props['screen'])
    const screenName = screen.value
    const storeNamespace =  screenName
    const store = useStore()
    const emitter: any = inject('emitter')

    const ticketCoverEnabled = computed<boolean>(() => !!store.getters['settings/show_cover'])
    const isShowLogs = computed(() => store.getters[storeNamespace + '/isShowLogs'] === '1')
    const notesOffset = computed(() => store.getters[storeNamespace + '/notesOffset'] as number)
    //const notesOffsetNext = computed(() => { console.log('notesOffsetNext', notesOffset.value, typeof notesOffset.value, notesOffset); return notesOffset.value - 0})
    //const isAddNoteFormMinimized = ref(true)

    const editNoteId = computed(() => store.getters[storeNamespace + '/editNoteId'])
    const replyNoteId = computed(() => store.getters[storeNamespace + '/replyNoteId'])
    const notes = computed(() => store.getters[storeNamespace + '/notes'])

    const canEditTasks = computed(() => store.getters[storeNamespace + '/canEditTasks'] || false)
    const topNoteForm = ref<InstanceType<typeof NoteEditForm> | null>(null)

    //const refNotesList = ref<InstanceType<typeof NotesList> | null>(null)
    //console.log('refNotesList', refNotesList, refNotesList.value)

    const {
      isSuccessServerResponse,
      showResponseSuccessMessage,
      isMayContinue,
      navigateWithConfirm,
      showRightPanelLoader,
      hideRightPanelLoader,
    } = useAppUtils()

    const {
      getNoteFormDraft,
      isNoteDraftForTicket,
      isNoteDraftForNoteReply,
      isNoteDraftForNoteEdit,
    } = useNoteDraft()

    watch(editNoteId, value => {
      const blockType = 'NotesPanel_editNote'
      value ? autorefreshBlockAdd(blockType, {editNoteId, screen: screen.value}) : autorefreshBlockRemove(blockType)
    })

    watch(replyNoteId, value => {
      const blockType = 'NotesPanel_replyNote'
      value ? autorefreshBlockAdd(blockType, {replyNoteId, screen: screen.value}) : autorefreshBlockRemove(blockType)
    })

    onBeforeRouteUpdate(() => {
      console.log('ConfirmContinue NotesPanel onBeforeRouteUpdate')
      resetReplyEditForms()
    })

    onBeforeRouteLeave(() => {
      console.log('ConfirmContinue NotesPanel onBeforeRouteLeave')
      resetReplyEditForms()
    })

    const {
      changeStateParams,
      getServerRequestParams,
      //processServerResponse,
      processServerResponseParam,
      fetchState,
    } = useFetchState({
      props: {},
      storeNamespace,
    })

    const refNotesContainer = ref<HTMLElement | null>(null)

    //const { y: scrollTop, arrivedState } = useScroll(refScrollableContainer)

    const { vConfirm } = useAppUtils()

    const resetData = async () => {
      store.commit(storeNamespace + '/set_notes', [])
      store.commit(storeNamespace + '/setTicketTasks', [])
      await resetNotesSearch()
    }

    const setShowLogs = async (isShow) => {
      //store.commit(storeNamespace + '/set_isShowLogs', isShow ? '1' : '0')
      const blockType = 'NotesPanel_setShowLogs'

      showRightPanelLoader()
      if (autorefreshBlockExists(blockType)) {
        return false
      }


      autorefreshBlockAdd(blockType)
      await changeStateParams({isShowLogs: isShow ? '1' : '0'})
      autorefreshBlockRemove(blockType)
      await hideRightPanelLoader()
    }

    // watch(() => store.getters[storeNamespace + '/isShowLogs'], async () => {
    //   await fetchState()
    // })

    const setNoteObjExpanded = async (key: string, value: boolean) => {
      const blockType = 'NotesPanel_toggleNotesExpanded'
      const blockAttrs = {key}
      if (autorefreshBlockExists(blockType, blockAttrs)) {
        return false
      }
      autorefreshBlockAdd(blockType, blockAttrs)
      store.commit(storeNamespace + '/set_noteObjExpanded', {key, value})
      try {
        await NotesService.toggleNotesExpanded(key, value ? '1' : '0')
      } catch (e) {
        console.log(e)
      }
      autorefreshBlockRemove(blockType, blockAttrs)
    }

    const isLoadingNotes = ref(false)
    const isSearchingNotes = ref(false)

    const searchNotes = async (noteSearch: string) => {
      const screenParams = {...getServerRequestParams()}
      delete screenParams.offset
      const params = {
        ...screenParams,
        project: getServerRequestParams().project,
        ticket: props['ticket'],
        noteSearch
      }
      const blockType = 'NotesPanel_searchNotes'
      console.log('NotesPanel searchNotes', noteSearch, params)
      // if (autorefreshBlockExists(blockType)) {
      //   return false
      // }
      autorefreshBlockAdd(blockType)
      isSearchingNotes.value = true
      try {
        const data: IApiResponse = await NotesService.loadNotes(params, screenName)
        console.log('NotesPanel searchNotes result', data, noteSearch)
          //debugger
          //await processServerResponse(data)

        ;['notes', 'notesForOffset', 'noteSearch', 'notesOffset', 'notesTimeOffset', 'isMoreNotes'].forEach(paramName => {
          processServerResponseParam(paramName, data)
        })
        await nextTick()

        requestAnimationFrame(() => {
          if (refNotesContainer.value) {
            const firstHighlightedResult = refNotesContainer.value.querySelector('.flash_s')
            console.log('firstHighlightedResult', firstHighlightedResult, refNotesContainer.value)
            if (firstHighlightedResult) {
              firstHighlightedResult.scrollIntoView({
                block: "center",
                inline: "nearest",
                behavior: "auto",
              })
            }
          }
        })

        isSearchingNotes.value = false

      } catch (e) {
        console.log('NotesPanel searchNotes error', e)
        if (e !== 'cancel') {
          isSearchingNotes.value = false
        }
      }
      autorefreshBlockRemove(blockType)
    }


    const showMoreNotes = async (offset: number) => {
      const params = {
        ...getServerRequestParams(),
        ticket: props['ticket'],
        offset
      }
      const blockType = 'NotesPanel_loadMoreNotes'
      console.log('NotesPanel showMoreNotes', offset, params)
      if (autorefreshBlockExists(blockType)) {
        return false
      }
      autorefreshBlockAdd(blockType)
      isLoadingNotes.value = true
      try {
        const data: IApiResponse = await NotesService.loadNotes(params, screenName)
        if (isSuccessServerResponse(data)) {
          ['notesForOffset', 'noteSearch', 'notesOffset', 'notesTimeOffset', 'isMoreNotes'].forEach(paramName => {
            processServerResponseParam(paramName, data)
          })
        }
          //debugger
          //await processServerResponse(data)

      } catch (e) {
        console.log('NotesPanel loadMoreNotes error', e)
      }
      isLoadingNotes.value = false
      autorefreshBlockRemove(blockType)
    }

    const refNoteSearchInput = ref()
    const noteSearchModel = ref(store.getters[storeNamespace + '/noteSearch'])
    const isShowNoteSearchForm = ref(!!noteSearchModel.value.trim())

    const showNotesSearchForm = async () => {
      isShowNoteSearchForm.value = true
      await nextTick()
      refNoteSearchInput.value?.focus()
    }

    const noteSearchSubmit = async () => {
      // store.commit(storeNamespace + '/set_noteSearch', event.target.value)
      // await fetchState()
      autorefreshBlockRemove('NotesPanel_waitNotesSearchSubmit')
      if (noteSearchModel.value === '' || noteSearchModel.value.trim()) {
        //await changeStateParams({noteSearch: noteSearchModel.value})
        await searchNotes(noteSearchModel.value)
      }
    }

    let ignoreNoteSearchModelChange = false
    const resetNotesSearch = async () => {
      isShowNoteSearchForm.value = false
      ignoreNoteSearchModelChange = true
      noteSearchModel.value = ''
      await nextTick()
      ignoreNoteSearchModelChange = false
      store.commit(storeNamespace + '/set_noteSearch', '')
    }

    const noteSearchCancel = async () => {
      const isChangeStateParamsNeeded = !!noteSearchModel.value.trim()
      //isShowNoteSearchForm.value = false
      //noteSearchModel.value = ''
      await resetNotesSearch()
      //if (store.getters[storeNamespace + '/noteSearch'].trim()) {
      if (isChangeStateParamsNeeded) {
        isSearchingNotes.value = true
        await changeStateParams({noteSearch: ''})
        isSearchingNotes.value = false
      }
      // store.commit(storeNamespace + '/set_noteSearch', '')
      // await fetchState()
    }

    const debouncedNoteSearchSubmit = _debounce(noteSearchSubmit, 500)

    const noteSearchInputHandler = () => {
      autorefreshBlockAdd('NotesPanel_waitNotesSearchSubmit')
      if (noteSearchModel.value === '' || noteSearchModel.value.trim()) {
        isSearchingNotes.value = true
      }
      debouncedNoteSearchSubmit()
    }

    watch(noteSearchModel, value => {
      console.log('NotesPanel debouncedNoteSearchSubmit watch noteSearchModel |', ignoreNoteSearchModelChange, '|', Date.now(), noteSearchModel.value)
      if (ignoreNoteSearchModelChange) {
        return
      }
      store.commit(storeNamespace + '/set_noteSearch', (value || '').trim())
      noteSearchInputHandler()
    })

    watch(() => store.getters[storeNamespace + '/noteSearch'], value => noteSearchModel.value = value)

    const confirmContinueHandler = () => {
      console.log('ConfirmContinue NotesPanel ContinueWithoutSavingConfirmed')
      resetReplyEditForms()
    }

    onBeforeUnmount(async () => {
      emitter.off('ContinueWithoutSavingConfirmed', confirmContinueHandler)
      await resetNotesSearch()
    })

    const gotoTicket = async (id: string) => {
      emit('gotoTicket', id)
      // if (!await isMayContinue()) { return false }
      // await changeStateParams({selectedItemId: id})
/*
      store.commit(storeNamespace + '/set_selectedItemId', id)
      const query = {
        ...currentRouteQuery.value,
        ticket: id
      }
      router.push({query})
*/

    }

    const isChangedBottomNoteForm = computed(() => store.getters[storeNamespace + '/isBottomFormChanged'])

    const editNoteHandler = async (id: string, note: INote) => {
      // if (!await isMayContinue()) { return false }
      console.log('editNoteHandler', isChangedBottomNoteForm.value, id, note)
      if (isChangedBottomNoteForm.value && !await vConfirm(t('Continue without Saving the updates?'))) {
        return false
      }
      resetReplyEditForms()
      // store.commit(storeNamespace + '/setReplyNoteId', '')
      // store.commit(storeNamespace + '/setReplyPositionNoteId', '')
      store.commit(storeNamespace + '/setEditNoteId', id)
      store.commit(storeNamespace + '/setEditNoteObj', _cloneDeep(note))

      // delete params.note
      // const response = await NotesService.editNote(params)
      // console.log('editNoteHandler server response', response)
      // if (!response.isError) {
      //   const noteObj = response?.xdata?.note
      //   if (noteObj) {
      //     store.commit(storeNamespace + '/setEditNoteObj', noteObj)
      //   }
      // }
    }

    const replyNoteHandler = async (reply: string, ticket_id: string) => {
      // if (!await isMayContinue()) { return false }
      console.log('replyNoteHandler', isChangedBottomNoteForm.value, reply, ticket_id)
      if (isChangedBottomNoteForm.value && !await vConfirm(t('Continue without Saving the updates?'))) {
        return false
      }
      resetReplyEditForms()
      const replyPositionNoteId = store.getters[storeNamespace + '/getReplyPositionId'](reply, ticket_id)
      console.log('replyNoteHandler replyPositionNoteId | ', replyPositionNoteId, '|',  reply)
      // store.commit(storeNamespace + '/setEditNoteId', '')
      if (replyPositionNoteId && reply) {
        store.commit(storeNamespace + '/setReplyPositionNoteId', replyPositionNoteId)
        store.commit(storeNamespace + '/setReplyNoteId', reply)
      }
      // store.commit(storeNamespace + '/setReplyNoteObj', {
      //   text: 'loading...',
      //   owner: {
      //     ...computed(()=>store.getters['auth/authUser']).value
      //   },
      // })
      // await NotesService.editNote(params)
    }

    const deleteNoteHandler = async (id: string) => {
      if(await vConfirm(t('Do you really want to delete this Note?'))) {
        store.commit(storeNamespace + '/removeNote', id)
        const blockType = 'NotesPanel_removeNote'
        const blockAttrs = {id}

        if (autorefreshBlockExists(blockType, blockAttrs)) {
          return false
        }

        autorefreshBlockAdd(blockType, blockAttrs)
        try {
          const response = await NotesService.removeNote({id})
          console.log('Delete note response', response)

          await showResponseSuccessMessage(response)

          if (response?.xdata?.restore_link) {
            store.commit(storeNamespace + '/addNoteAtPosition', {note: response.xdata.restore_link, positionNote: '0'})
          }
        } catch (e) {
          console.log('NotesPanel removeNote error', e)
          await refreshScreen()
        }
        autorefreshBlockRemove(blockType, blockAttrs)
      }
    }

    const resetReplyEditForms = () => {
      console.log('ConfirmContinue NotesPanel resetReplyEditForms')
      store.commit(storeNamespace + '/setEditNoteId', '')
      store.commit(storeNamespace + '/setReplyPositionNoteId', '')
      store.commit(storeNamespace + '/setReplyNoteId', '')
      //setNoteFormChanged(false, 'top')
      setNoteFormChanged(false, 'bottom')
    }

    const cancelNoteHandler = (id: string) => {
      console.log('cancelNoteHandler', id)
      resetReplyEditForms()
    }

    const beforeSaveNoteHandler = (params: {note: IData, positionNote?: string}) => {
      console.log('beforeSaveNoteHandler', params)
      // if (!params.note.id && params.positionNote) {
      //   store.commit(storeNamespace + '/addNoteAtPosition', params)
      // } else {
      //   store.commit(storeNamespace + '/updateNote', params.note)
      // }
      // resetReplyEditForms()
    }

    const { highlightNote } = useHighlight()

    const saveNoteHandler = async (params: {note: IData, local_id?: string, positionNote?: string}) => {
      console.log('saveNoteHandler', params)
      // if (params.local_id) {
      //   store.commit(storeNamespace + '/updateNoteByLocalId', params)
      // } else {
      //   store.commit(storeNamespace + '/updateNote', params.note)
      // }

      if (params.local_id && params.positionNote) {
        store.commit(storeNamespace + '/addNoteAtPosition', params)
      } else {
        store.commit(storeNamespace + '/updateNote', params.note)
      }
      resetReplyEditForms()

      await nextTick()
      // const noteElement = document.querySelector(`.message-item[data-id="${params.note.id}"]`)
      // if (noteElement) {
      //   noteElement.classList.add('highlight')
      //   setTimeout(() => { noteElement.classList.remove('highlight') }, 1700)
      // }

      emit('noteSaved', params.note)
      await highlightNote(params.note.id)

      //await fetchState() // todo: update note data from response
    }

    const addNoteHandler = async (params: {note: IData, local_id?: string, positionNote?: string}) => {
      console.log('addNoteHandler', params.note.depth, params, JSON.stringify(params))
      //params.note.depth = '0'
      params.positionNote = '0'
      await saveNoteHandler(params)
      //await fetchState()
    }

    const setNoteFormChanged = (isChanged: boolean, formType: string) => {
      console.log('setNoteFormChanged', isChanged, formType)
      store.commit(storeNamespace + '/setNoteFormChanged', {value: isChanged, type: formType})
    }

    const changeUploadActive  = (isActive: boolean, formType: string) => {
      console.log('NotesPanel changeUploadActive', isActive, formType)
      if (screen.value === 'ticket') {
        store.commit(storeNamespace + '/setNoteFormUploading', {value: isActive, type: formType})
      }
    }

    // const cancelNewNoteHandler = () => {
    //   console.log('cancelNewNoteHandler')
    //   //isAddNoteFormMinimized.value = true
    // }

    const setCover = async (ticketId, attachmentId) => {

      // autorefreshBlockAdd('test', {uid: 'uid', attr: 'attr'})
      // autorefreshBlockAdd('test', {attr: 'attr'})
      // autorefreshBlockAdd('test', {uid: 'uid'})
      // autorefreshBlockAdd('test')
      // autorefreshBlockAdd('test2', {uid: 'uid'})
      // autorefreshBlockAdd('test2', {attr: 'attr'})
      // autorefreshBlockAdd('test2')
      // autorefreshBlockAdd('test3', {attr: 'attr3'})


      const blockType = 'NotesPanel_setCover'
      const blockAttrs = {ticketId, attachmentId}

      if (autorefreshBlockExists(blockType, blockAttrs)) {
        return false
      }

      autorefreshBlockAdd(blockType, blockAttrs)

      // console.log('autorefreshBlockExists', autorefreshBlockExists('NotesPanel_setCover'))
      // console.log('autorefreshBlockExists', autorefreshBlockExists('test2'))
      // console.log('autorefreshBlockExists', autorefreshBlockExists('test4'))
      // console.log('autorefreshBlockExists', autorefreshBlockExists('test', {uid: 'uid', attr: 'attr'}))
      // console.log('autorefreshBlockExists', autorefreshBlockExists('test', {attr: 'attr'}))
      // console.log('autorefreshBlockExists', autorefreshBlockExists('test', {uid: 'uid', attr: 'attr', foo: 'bar'}))


      try {
        const cancelTokenId = `setCover_${ticketId}`
        ApiService.cancelRequest(cancelTokenId)
        const result = await TicketService.setCover(ticketId, attachmentId, cancelTokenId)
        showResponseSuccessMessage(result)
        if (isSuccessServerResponse(result)) {
          store.commit(storeNamespace + '/setTicketCover',{ticketId, attachmentId})
        }
      } catch (e) {
        console.log('NotesPanel setCover error', e)
      }
      autorefreshBlockRemove(blockType, blockAttrs)
    }

    const makeCoverHandler = async (ticketId, attachmentId) => {
      await setCover(ticketId, attachmentId)
    }

    const removeCoverHandler = async (ticketId) => {
      await setCover(ticketId, '-1')
    }

    onMounted(async () => {
      console.log('NotesPanel onMounted', props['ticket'], notes.value?.length, notes.value)
      await nextTick()
      await checkNoteDraft()
      emitter.off('ContinueWithoutSavingConfirmed', confirmContinueHandler)
      emitter.on('ContinueWithoutSavingConfirmed', confirmContinueHandler)
    })

    watch(() => props['ticket'], async (value) => {
      console.log('NotesPanel watch props.ticket', value)
      if (!value) {
        store.commit(storeNamespace + '/setTicketTasks', [])
      }
      store.commit(storeNamespace + '/set_notesOffset', 0)
      await resetNotesSearch()
      //checkNoteDraft()
      const stopWatchingNotes = watch(notes, async () => {
        stopWatchingNotes()
        await checkNoteDraft()
      })

    })

    const ticketTasksComponent = ref<InstanceType<typeof TicketTasks> | null>(null)
    const isActiveTicketTasks = computed(() => ticketTasksComponent.value && ticketTasksComponent.value.isActive)

    const noteMenu = ref<InstanceType<typeof MenuNote> | null>(null)
    const noteMenuPositionTarget = ref(null)
    const noteMenuNote = ref<IData | null>(null)
    //const isExistNoteMenu = ref(false)
    //const isHidingNoteMenu = ref(false)
    // const isExistNoteMenu = computed(() => {
    //   console.log('NotesPanel isExistNoteMenu', isTriggeredNoteMenu.value, noteMenu.value?.isOpened, noteMenu.value?.isHiding)
    //   return isTriggeredNoteMenu.value || noteMenu.value?.isOpened
    // })


    const {isExist: isExistNoteMenu, triggerHandler: triggerHandlerNoteMenu} = useFloatingExists(noteMenu)
    const activeMenuNoteId = computed(() => isExistNoteMenu.value && noteMenu.value?.isOpened && noteMenuNote.value?.id ? ('' + noteMenuNote.value.id) : '')

    watch(activeMenuNoteId, value => {
      const blockType = 'NotesPanel_noteMenu'
      value ? autorefreshBlockAdd(blockType, {noteId: value, screen: screen.value}) : autorefreshBlockRemove(blockType)
    })

    // watch(() => noteMenu.value?.isHiding, async (newValue, oldValue) => {
    //   //debugger
    //   if (!newValue && oldValue !== undefined) {
    //     await nextTick()
    //     //isHidingNoteMenu.value = false
    //     isExistNoteMenu.value = false
    //   }
    // })

    const noteMenuTriggerHandler = async (params) => {
      console.log('NotesPanel noteMenuHandler', params?.note?.id)
      //debugger
      // if (noteMenu.value && noteMenuPositionTarget.value === params.targetElement) {
      //   noteMenu.value.isOpened = false
      //   return
      // }

      noteMenuPositionTarget.value = params.targetElement
      noteMenuNote.value = params.note

      await triggerHandlerNoteMenu()

      // if (!noteMenu.value) {
      //   isExistNoteMenu.value = true
      // }
      //
      // await nextTick()
      // if (noteMenu.value) {
      //   noteMenu.value.isOpened = true
      //
      // }

    }

    const noteMenuActionHandler = (action: string, note: INote) => {
      console.log('noteMenuActionHandler', action, note)
      if (note?.id && note?.ticket_id) {
        const noteId = note.id.toString()
        switch (action) {
          case 'edit':
            editNoteHandler(noteId, note)
            break
          case 'reply':
            replyNoteHandler(noteId, note.ticket_id)
            break
          case 'delete':
            deleteNoteHandler(noteId)
            break
        }
      }
      noteMenu.value.isOpened = false
    }

    const toggleTextShortMode = async (noteId) => {
      const blockType = 'NotesPanel_toggleTextShortMode'
      const blockAttrs = {noteId}

      if (autorefreshBlockExists(blockType, blockAttrs)) {
        return false
      }

      autorefreshBlockAdd(blockType, blockAttrs)

      try {
        const data: IApiResponse = await NotesService.getNoteText(noteId)
        console.log('NotesPanel getNoteText success', data)
        if (data?.xdata?.id && data?.xdata?.text) {
          data.xdata.id = data.xdata.id.toString()
          store.commit(storeNamespace + '/setNoteText', data.xdata)
        }
      } catch (e) {
        console.log('NotesPanel getNoteText error', e)
      }

      autorefreshBlockRemove(blockType, blockAttrs)

    }


/*
    setTimeout(async () => {
      noteMenuPositionTarget.value = testButton.value
      await nextTick()

      setTimeout(() => { console.log('NoteMenu', noteMenu.value) }, 2000)
    }, 4000)
*/

    //setTimeout(() => { noteMenuPositionTarget.value = null }, 8000)

    // watch(() => noteMenu.value?.isOpened, (value) => {
    //   if (!value) {
    //     setTimeout(() => { noteMenuPositionTarget.value = null }, 500)
    //   }
    // })

    const ticketTasksCountChangeHandler = (data) => {
      console.log('NotesPanel ticketTasksCountChangeHandler', data)
      emit('ticketTasksCountChanged', data)
    }

    const checkNoteDraft = async () => {
      console.log('NotesPanel checkNoteDraft', props['ticket'])
      const formDraft = getNoteFormDraft('bottom')
      if (props['ticket'] && !isNoteDraftForTicket(formDraft, props['ticket'])) {
        return
      }

      if (formDraft) {
        await nextTick()
        if (formDraft.id && isNoteDraftForNoteEdit(formDraft, formDraft.id)) {
          await editNoteHandler(formDraft.id, formDraft.note)
        } else if (formDraft.reply && formDraft.ticket_id && isNoteDraftForNoteReply(formDraft, formDraft.reply)) {
          await replyNoteHandler(formDraft.reply, formDraft.ticket_id)
        }
      }
    }

    const focusTopNoteForm = async () => {
      await nextTick()
      console.log('tableRowClickHandler refNotesPanel focusTopNoteForm', topNoteForm.value)
      if (topNoteForm.value) {
        await topNoteForm.value.focusRichEditor()
      }
    }

    // const isUploadInProgress = () => {
    //   console.log('NotesPanel isUploadInProgress', topNoteForm.value, topNoteForm.value && topNoteForm.value.isUploadInProgress(), refNotesList.value, refNotesList.value && refNotesList.value.isUploadInProgress)
    //   console.log('refNotesList', refNotesList, refNotesList.value)
    //   return false //(topNoteForm.value && topNoteForm.value.isUploadInProgress()) || (refNotesList.value && refNotesList.value.isUploadInProgress())
    // }

    return {
      refNotesContainer,
      //scrollTop, arrivedState,
      t,
      notes: computed(() => store.getters[storeNamespace + '/notes']),
      addNoteDisabled: computed(() => store.getters[storeNamespace + '/addNoteDisabled']),
      isMoreNotes: computed(() => store.getters[storeNamespace + '/isMoreNotes']),
      noSearchResults: computed(() => store.getters[storeNamespace + '/notes'].length === 0 && store.getters[storeNamespace + '/noteSearch'].trim()),
      editNoteId,
      replyNoteId,
      replyPositionNoteId: computed(() => store.getters[storeNamespace + '/replyPositionNoteId']),
      newNoteObj: computed(() => store.getters[storeNamespace + '/newNoteObj']),
      editNoteObj: computed(() => store.getters[storeNamespace + '/editNoteObj']),
      replyNoteObj: computed(() => store.getters[storeNamespace + '/replyNoteObj']),
      activeMenuNoteId,
      noteMenu,
      noteMenuPositionTarget,
      noteMenuNote,
      isExistNoteMenu,
      //isTriggeredNoteMenu,
      isShowLogs,
      isShowNoteSearchForm,
      isLoadingNotes,
      isSearchingNotes,
      notesOffset,
      ticketTasksComponent,
      isActiveTicketTasks,
      //isAddNoteFormMinimized,
      setShowLogs,
      setNoteObjExpanded,
      showMoreNotes,
      showNotesSearchForm,
      refNoteSearchInput,
      noteSearchModel,
      noteSearchSubmit,
      debouncedNoteSearchSubmit,
      noteSearchCancel,
      gotoTicket,
      //editNoteHandler,
      replyNoteHandler,
      //deleteNoteHandler,
      cancelNoteHandler,
      beforeSaveNoteHandler,
      saveNoteHandler,
      addNoteHandler,
      setNoteFormChanged,
      changeUploadActive,
      //cancelNewNoteHandler,
      makeCoverHandler,
      removeCoverHandler,
      noteMenuTriggerHandler,
      noteMenuActionHandler,
      toggleTextShortMode,
      ticketTasksCountChangeHandler,
      resetData,
      navigateWithConfirm,
      canEditTasks,
      topNoteForm,
      //refNotesList,
      focusTopNoteForm,
      //isUploadInProgress,
      ticketCoverEnabled,
    }
  }

})
