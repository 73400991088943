import {defineComponent, PropType, ref, watch, nextTick, onBeforeUnmount} from "vue";
import UserAvatar from "@/components/UserAvatar"
import {useI18n} from "vue-i18n"
import {IData, ITableField} from "@/types"
import SvgIcon from "@/components/SvgIcon"
import {useAppUtils} from "@/composables/useAppUtils"
import {autorefreshBlockAdd, autorefreshBlockRemove} from "@/composables/useFetchState"
import _throttle from 'lodash/throttle'
import {useResizeObserver} from "@/composables/useResizeObserver"

export default defineComponent({
  name: 'DataTable',
  props: {
    items: {
      type: Array as PropType<IData[]>,
      default: () => [],
    },
    columns: {
      type: Array as PropType<ITableField[]>,
      default: () => [],
    },
    hasTableHead: {
      type: Boolean,
      default: true,
    },
    selectedId: {
      type: String,
      required: false,
    },
    isMultieditMode: {
      type: Boolean,
      default: false,
    },
    checkedItems: {
      type: Object as PropType<IData>,
      required: false,
    },
    sortBy: {
      type: String,
      required: false
    },
    sortDesc: {
      type: Boolean,
      default: false,
      required: false
    },
    fixedRow: {
      type: Boolean,
      default: false,
      required: false
    },
    enableColumnsResizing: {
      type: Boolean,
      default: true,
      required: false
    },
    expanderColumnWidth: {
      type: Number,
      default: 0,
      required: false
    },
    defaultCellContent: {
      type: String,
      default: '',
      required: false
    }
  },

  emits: [
    'itemClicked',
    'headCellClicked',
    'changeSortDesc',
    'changeSortBy',
    'changeColumns',
    'resizeColumn',
    'headContextmenu',
    'changeWidth',
  ],
  components: {
    UserAvatar,
    SvgIcon
  },
  setup(props, {emit, slots, attrs}) { //props, context

    console.log('DataTable setup', props, slots)

    const { t } = useI18n()

    const fieldsModel = ref<ITableField[]>([])
    const refDataTableContainer = ref()

    let resizeObserverInitialized = false
    //let resizeObserver: any = null

    let headCellClickBlocked = false

    const {
      getMinDarkColor,
      getDragEventPageX,
      getScrollParent,
      getScrollbarWidth,
    } = useAppUtils()


    const getFieldByName = (name) => fieldsModel.value.find(item => item.fieldname === name)

    const adjustTable = (availableWidth = 0) => {
      //console.log('adjustTable availableWidth 1', availableWidth)
      if (!availableWidth) {
        if (refDataTableContainer.value) {
          availableWidth = refDataTableContainer.value.getBoundingClientRect().width
        }
      }
      //console.log('adjustTable availableWidth 2', availableWidth)
      if (!availableWidth) {
        return
      }
      let autowidthColumn: ITableField | undefined
      let columnsOccupiedWidth = props.expanderColumnWidth; // ширина последней колонки

      fieldsModel.value.forEach((item: ITableField) => {
        if (item.autowidth) {
          autowidthColumn = item
        } else if (item.visible.toString() === '1') {
          columnsOccupiedWidth += parseFloat(item.width.toString())
        }
      })
      const autoWidthValue = availableWidth - columnsOccupiedWidth
      if (autowidthColumn) {
        autowidthColumn.computed_width = autoWidthValue > autowidthColumn.width ? autoWidthValue : parseFloat(autowidthColumn.width.toString())
      }
      emit('changeWidth', availableWidth)
      //console.log('adjustTable', availableWidth, columnsOccupiedWidth, autoWidthValue, autowidthColumn?.width, autowidthColumn)
    }

    // if ('ResizeObserver' in self) {
    //   resizeObserver = new window['ResizeObserver']((entries) => {
    //     for (const entry of entries) {
    //       console.log('ResizeObserver entry', entry, entry.target === refDataTableContainer.value)
    //       if (entry.target === refDataTableContainer.value) {
    //         adjustTable(entry.contentRect.width)
    //       }
    //     }
    //
    //   });
    // }

    const {
      addResizeObserverItem,
      removeResizeObserverItem,
    } = useResizeObserver()

    const resizeObserverHandler = _throttle(entry => {
      adjustTable(entry.contentRect.width)
    }, 100)
    let resizeObserverItemUid
    const setInitialValues = async () => {
      fieldsModel.value = []
      props.columns.forEach(item => {
        fieldsModel.value.push({...item})
      })

      console.log('ResizeObserver observe', refDataTableContainer.value)
      if (!resizeObserverInitialized && refDataTableContainer.value) {
        resizeObserverItemUid = addResizeObserverItem(refDataTableContainer.value, resizeObserverHandler)
        resizeObserverInitialized = true
      }

      await nextTick()
      adjustTable()
      console.log('setInitialValues', document.querySelector('.data-table-container')?.getBoundingClientRect())
    }

    onBeforeUnmount(() => {
      removeResizeObserverItem(resizeObserverItemUid)
      removeDragListeners()
    })

    setInitialValues()
    watch(() => props.columns, setInitialValues)

    const clickItemRowHandler = (id: number) => {
      emit('itemClicked', id)
    }

    const headCellClickHandler = (event, field) => {
      console.log('headCellClickHandler', isResizingColumn.value, event.target.classList.contains('cell-resizer'))
      if (headCellClickBlocked || isResizingColumn.value || event.target.classList.contains('cell-resizer')) {
        return
      }
      emit('headCellClicked', event, field)
      if (field.can_sort) {
        if (field.fieldname === props.sortBy) {
          emit('changeSortDesc', !props.sortDesc)
        } else {
          //emit ('changeSortBy', field.fieldname, props.sortBy)
          emit ('changeSortBy', field.fieldname)
        }
      }
    }

    const columnsOrderChanged = () => {
      const newFields: ITableField[] = []
      fieldsModel.value.forEach((field, index) => {
        if (field.fieldname === 'checkbox') {
          return
        }
        const newField = {...field}
        newField.orders = index.toString()
        newFields.push(newField)
      })
      console.log('columnsOrderChanged', newFields)
      emit('changeColumns', newFields)

    }

    let resizeColumnStartX = 0
    //let resizeColumnActive = false
    // const columnResizeStart = (event) => {
    //   console.log('columnResizeStart', event)
    //   resizeColumnStartX = event.pageX
    //   resizeColumnActive = true
    // }

    const tableHeadContextMenuHandler = (event) => {
      console.log('tableHeadContextMenuHandler', event)
      emit('headContextmenu', event)
    }

    const minDarkColor = getMinDarkColor()

    const refDataTableHead = ref<HTMLElement | null>(null)
    const draggingCell = ref('')
    const dragTargetCell = ref('')
    const refMovingPreview = ref<HTMLElement | null>(null)
    const isMovingColumn = ref(false)
    const isResizingColumn = ref(false)
    const refMoveResizeColumnHint = ref<HTMLElement | null>(null)

    let initializedDragAction = ''
    let initialDragX = 0

    interface IDataTableCellData {
      fieldName: string
      canMove: string
      rect: DOMRect
      element: HTMLElement
    }

    let cellsData: IDataTableCellData[] = []
    const updateCellsData = () => {
      const tableHead = refDataTableHead.value
      if (tableHead) {
        cellsData = []
        tableHead.querySelectorAll('th').forEach(cell => {
          if (cell.dataset.fieldname) {
            cellsData.push({
              fieldName: cell.dataset.fieldname,
              canMove: cell.dataset.canMove || '0',
              rect: cell.getBoundingClientRect(),
              element: cell
            })
          }
        })
      }
      console.log('updateCellsData', cellsData)
    }

    let containerData: {element?: HTMLElement, rect?: any} = {}
    const updateContainerData = () => {
      containerData = {}
      const scrollableContainerElement = getScrollParent(refDataTableContainer.value)
      if (scrollableContainerElement) {
        Object.assign(containerData, {
          element: scrollableContainerElement,
          rect: scrollableContainerElement.getBoundingClientRect()
        })
      }
      console.log('updateContainerData', scrollableContainerElement)
    }

    let scrollContainerOnDragIntervalTimer = 0
    const scrollContainerOnDragDeltaX = 2
    const scrollContainerOnDragDeltaTime = 5
    const scrollContainerOnDrag = (pageX) => {
      console.log('scrollContainerOnDrag', pageX, containerData)
      let isScroling = false
      if (containerData?.element && containerData?.rect) {
        if (pageX > containerData.rect.x + containerData.rect.width) {
          isScroling = true
          if (!scrollContainerOnDragIntervalTimer) {
            scrollContainerOnDragIntervalTimer = setInterval(() => {
              if (containerData.element) {
                containerData.element.scrollBy(scrollContainerOnDragDeltaX, 0)
                updateCellsData()
              }
            }, scrollContainerOnDragDeltaTime)
          }
        } else if (pageX < containerData.rect.x) {
          isScroling = true
          if (!scrollContainerOnDragIntervalTimer) {
            scrollContainerOnDragIntervalTimer = setInterval(() => {
              if (containerData.element) {
                containerData.element.scrollBy(-scrollContainerOnDragDeltaX, 0)
                updateCellsData()
              }
            }, scrollContainerOnDragDeltaTime)

          }
        } else {
          clearInterval(scrollContainerOnDragIntervalTimer)
          scrollContainerOnDragIntervalTimer = 0
        }
      }
      return isScroling
    }


    const getCellDataByPageX = (pageX) => cellsData.find((cell: IDataTableCellData) => {
      console.log('getCellDataByPageX', pageX, containerData.element?.scrollLeft, cell.fieldName, cell.rect.x, cell.rect.width)
      //const xCoord = containerData?.element?.scrollLeft ? pageX + containerData.element.scrollLeft : pageX
      const xCoord = pageX
      return xCoord >= cell.rect.x && xCoord <= cell.rect.x + cell.rect.width
    })

    let headColCached: HTMLElement | null = null
    let bodyColCached: HTMLElement | null = null
    let headCellCached: HTMLElement | null = null

    const mousemoveHandler = event => {
      const pageX = getDragEventPageX(event)
      console.log('mousemoveHandler pageX', pageX)

      if (pageX === null) {
        return
      }

      if (initializedDragAction && Math.abs(pageX - initialDragX) > 4) {
        if (initializedDragAction === 'move') {
          isMovingColumn.value = true
        }
        updateCellsData()
        initializedDragAction = ''
      }

      if (isMovingColumn.value) {
        // if (refMoveResizeColumnHint.value) {
        //   //refMoveResizeColumnHint.value.style.top = event.pageY + 'px'
        //   refMoveResizeColumnHint.value.style.left = event.pageX + 'px'
        // }

        if (scrollContainerOnDrag(pageX)) {
          dragTargetCell.value = ''
          return
        }

        updateMoveResizeHint(event)

        const cellData = getCellDataByPageX(pageX)
        console.log('mousemoveHandler cellData', pageX, cellData?.fieldName, cellData, cellsData)
        if (!(cellData && cellData.element)) {
          dragTargetCell.value = ''
          return
        }
        const cell = cellData.element //event.target.closest('th')

        if (cell.dataset.canMove !== '1') {
          dragTargetCell.value = ''
          return
        }

        updateMovingPreview(event, cell)


      } else if (isResizingColumn.value && refDataTableHead.value) {
        // if (refMoveResizeColumnHint.value) {
        //   //refMoveResizeColumnHint.value.style.top = event.pageY + 'px'
        //   refMoveResizeColumnHint.value.style.left = event.pageX + 'px'
        // }

        // const table = refDataTableHead.value.closest('table')
        // if (table) {
          const col: HTMLElement | null = headColCached //table.querySelector('[data-name="' + draggingCell.value + '"]')
          const column = getFieldByName(draggingCell.value)
          if (col && column) {
            // const columnMinWidth = column.minwidth || 50
            // const columnWidth = Math.round(Math.max(columnMinWidth, (parseFloat((column.computed_width || column.width).toString()) + event.pageX - resizeColumnStartX)))
            const columnWidth = getResizingColumnWidth(event, column)

            if (columnWidth !== null) {
              col.style.width = columnWidth  + 'px'
              console.log('mousemoveHandler', column.fieldname, columnWidth)

              const cell = headCellCached //refDataTableHead.value.querySelector('th[data-fieldname="' + column.fieldname + '"]')
              updateMoveResizeHint(event, columnWidth, cell)

              //refDataTableContainer.value.querySelector('.data-table-body col[data-name="' + column.fieldname + '"]').style.width = columnWidth  + 'px'
              if (bodyColCached) {
                bodyColCached.style.width = columnWidth  + 'px'
              }
            }

            // if (refMoveResizeColumnHint.value) {
            //   const columnWidthElement = refMoveResizeColumnHint.value.querySelector('.column-width-value') as HTMLElement
            //   if (columnWidthElement) {
            //     columnWidthElement.innerText = columnWidth.toString()
            //   }
            //   const colRect = col.getBoundingClientRect()
            //   refMoveResizeColumnHint.value.style.left = Math.max(event.pageX, colRect.x + colRect.width) + 'px'
            // }
          }
        //}
      }
    }

    const updateMovingPreview = (event, cell) => {
      const pageX = getDragEventPageX(event)
      if (pageX === null) {
        return
      }
      const nextCell = cell.nextElementSibling as HTMLElement
      const rect = cell.getBoundingClientRect()
      console.log('mousemoveHandler', pageX, rect.x, rect.x + rect.width, rect.y, rect, event, nextCell, cell.dataset.fieldname)
      if (!nextCell) {
        dragTargetCell.value = cell.dataset.fieldname || ''
        if (refMovingPreview.value) {
          refMovingPreview.value.style.left = rect.x + 'px'
          refMovingPreview.value.style.top = (rect.y - 8) + 'px'
        }
        return
      }
      if (pageX >= rect.x && pageX <= rect.x + rect.width/2) {
        dragTargetCell.value = cell.dataset.fieldname || ''
        if (refMovingPreview.value) {
          refMovingPreview.value.style.left = rect.x + 'px'
          refMovingPreview.value.style.top = (rect.y - 8) + 'px'
        }
      } else if (pageX > rect.x + rect.width/2) {
        dragTargetCell.value = nextCell.dataset.fieldname || ''
        if (refMovingPreview.value) {
          refMovingPreview.value.style.left = nextCell.getBoundingClientRect().x + 'px'
          refMovingPreview.value.style.top = (nextCell.getBoundingClientRect().y - 8) + 'px'
        }
      }
      // if (refMovingPreview.value) {
      //   refMovingPreview.value.scrollIntoView()
      // }
    }

    const initMoveResizeHint = (event, cell) => {
      if (cell && refMoveResizeColumnHint.value) {
        const cellRect = cell.getBoundingClientRect()
        const cellTop = cellRect.y

        const topOffset = isResizingColumn.value ? cellTop - 30 : cellTop + 32
        refMoveResizeColumnHint.value.style.top = topOffset + 'px'
        const columnNameElement = refMoveResizeColumnHint.value.querySelector('.column-name') as HTMLElement
        if (columnNameElement) {
          columnNameElement.innerText = cell.innerText
        }

        updateMoveResizeHint(event, Math.round(cellRect.width), cell)
      }
    }

    const updateMoveResizeHint = (event, width?, col?) => {
      const pageX = getDragEventPageX(event)
      if (pageX === null) {
        return
      }
      if (refMoveResizeColumnHint.value) {
        let leftOffset = pageX

        if (isResizingColumn.value) {
          if (width) {
            const columnWidthElement = refMoveResizeColumnHint.value.querySelector('.column-width-value') as HTMLElement
            if (columnWidthElement) {
              columnWidthElement.innerText = width
            }
          }
          if (col) {
            const colRect = col.getBoundingClientRect()
            if (pageX < colRect.x + colRect.width) {
              leftOffset = colRect.x + colRect.width
            }
            console.log('updateMoveResizeHint', colRect.x, colRect.width, leftOffset, colRect)
          }
        }

        refMoveResizeColumnHint.value.style.left = leftOffset + 'px'
      }
    }

    const getResizingColumnWidth = (event, column) => {
      const pageX = getDragEventPageX(event)
      if (pageX === null) {
        return null
      }
      const columnMinWidth = column.minwidth || 50
      const columnStartWidth = column.autowidth ? column.computed_width : parseFloat(column.width.toString())
      return  Math.round(Math.max(columnMinWidth, (columnStartWidth + pageX - resizeColumnStartX)))

    }

    const throttledMousemoveHandler = _throttle(mousemoveHandler, 100, {
      'leading': true,
      'trailing': true
    })

    const addDragListeners = () => {
      document.addEventListener('mousemove', throttledMousemoveHandler)
      document.addEventListener('mouseup', mouseupHandler)
      document.addEventListener('touchmove', throttledMousemoveHandler)
      document.addEventListener('touchend', mouseupHandler)
    }

    const removeDragListeners = () => {
      document.removeEventListener('mousemove', throttledMousemoveHandler)
      document.removeEventListener('mouseup', mouseupHandler)
      document.removeEventListener('touchmove', throttledMousemoveHandler)
      document.removeEventListener('touchend', mouseupHandler)
    }

    const mousedownHandler = async event => {
      autorefreshBlockAdd('DataTable_moveResizeColumn')

      addDragListeners()

      const pageX = getDragEventPageX(event)
      if (pageX === null) {
        return
      }
      const cell = event.target.closest('th')
      const resizer = event.target.closest('.cell-resizer')

      if (resizer) {

        isResizingColumn.value = true
        initializedDragAction = 'resize'
        draggingCell.value = cell.dataset.fieldname || ''

        resizeColumnStartX = pageX

        try {
          headColCached = refDataTableContainer.value.querySelector('.data-table-head col[data-name="' + draggingCell.value + '"]')
          bodyColCached = refDataTableContainer.value.querySelector('.data-table-body col[data-name="' + draggingCell.value + '"]')
          headCellCached = cell
        } catch (e) {
          console.log('DataTable col cache error', e)
        }

        //resizeColumnActive = true

        //columnResizeStart(event)
        await nextTick()
      } else {
        if (!(cell && cell.dataset.canMove === '1')) {
          return false
        }
        //isMovingColumn.value = true
        initializedDragAction = 'move'
        draggingCell.value = cell.dataset.fieldname || ''

        updateContainerData()

      }
      initialDragX = pageX
      //mousemoveHandler(event)

      initMoveResizeHint(event, cell)


      // if (cell && refMoveResizeColumnHint.value) {
      //   const cellTop = cell.getBoundingClientRect().y
      //   moveResizeHintColumnName.value = cell.innerText
      //   moveResizeHintStyleObject.top = (resizer ? cellTop - 30 : cellTop + 32) + 'px'
      //   updateMoveResizeHint(event, cell)
      //   // const cellTop = cell.getBoundingClientRect().y
      //   // const topOffset = resizer ? cellTop - 30 : cellTop + 32
      //   // refMoveResizeColumnHint.value.style.top = topOffset + 'px'
      //   // const columnNameElement = refMoveResizeColumnHint.value.querySelector('.column-name') as HTMLElement
      //   // if (columnNameElement) {
      //   //   columnNameElement.innerText = cell.innerText
      //   // }
      // }


      console.log('mousedownHandler', pageX, headColCached, headCellCached, bodyColCached, event)
    }

    const mouseupHandler = async (event) => {
      mousemoveHandler(event)
      clearInterval(scrollContainerOnDragIntervalTimer)
      scrollContainerOnDragIntervalTimer = 0
      console.log('mouseupHandler')
      if (isMovingColumn.value || isResizingColumn.value) {
        headCellClickBlocked = true
        setTimeout(() => { headCellClickBlocked = false }, 50)
        if (isMovingColumn.value && draggingCell.value && dragTargetCell.value && draggingCell.value !== dragTargetCell.value) {
          console.log('mouseupHandler', draggingCell.value, dragTargetCell.value, fieldsModel.value)

          const newFieldsArray: ITableField[] = []
          //const draggingFieldModel: ITableField | undefined = canMoveFieldsModel.value.find(item => item.fieldname === draggingCell.value)
          const draggingFieldModel: ITableField | undefined = getFieldByName(draggingCell.value)
          if (draggingFieldModel) {
            fieldsModel.value.forEach(item => {
              if (item.fieldname === dragTargetCell.value) {
                newFieldsArray.push(draggingFieldModel)
              } else if (item.fieldname === draggingCell.value) {
                return
              }
              newFieldsArray.push(item)
            })
            if (dragTargetCell.value === 'last' || dragTargetCell.value === 'beforelast') {
              newFieldsArray.push(draggingFieldModel)
            }
            fieldsModel.value = newFieldsArray
            console.log('mouseupHandler', newFieldsArray)
            columnsOrderChanged()
          }
        }
        if (isResizingColumn.value && draggingCell.value) {
          // let column = canMoveFieldsModel.value.find(item => item.fieldname === draggingCell.value)
          // if (!column) {
          //   column = cannotMoveFieldsModel.value.find(item => item.fieldname === draggingCell.value)
          // }
          const column = getFieldByName(draggingCell.value)
          if (column) {
            // const table = document.querySelector('.data-table.data-table-body')
            // if (table) {
            //   const col: HTMLElement | null = table.querySelector('[data-name="' + draggingCell.value + '"]')
            //   if (col && column) {
            //     col.style.width = (parseFloat(column.width) + event.pageX - resizeColumnStartX) + 'px'
            //   }
            //
            // }


            // const columnMinWidth = column.minwidth || 50
            // const columnStartWidth = column.autowidth ? column.computed_width : parseFloat(column.width.toString())
            // const columnWidth = Math.round(Math.max(columnMinWidth, (columnStartWidth + event.pageX - resizeColumnStartX)))
            if (headColCached) {
              const columnWidth = Math.round(headColCached.getBoundingClientRect().width) //getResizingColumnWidth(event, column)
              column.width = columnWidth.toString()
              emit('resizeColumn', {
                ...column
              })
            }


            // if (column.fieldname !== 'title') {
            //   const titleColumn = cannotMoveFieldsModel.value.find(item => item.fieldname === 'title')
            //   if (titleColumn) {
            //     titleColumn.width = titleWidthBeforeResize
            //   }
            // }

            await nextTick()
            adjustTable()

          }
          //requestAnimationFrame(() => {resizeColumnActive = false})
        }
      }
      isMovingColumn.value = false
      isResizingColumn.value = false
      draggingCell.value = ''
      dragTargetCell.value = ''
      headColCached = null
      bodyColCached = null
      headCellCached = null

      removeDragListeners()
      autorefreshBlockRemove('DataTable_moveResizeColumn')
    }

    // useEventListener(document, 'mousemove', mousemoveHandler)
    // useEventListener(document, 'mouseup', mouseupHandler)


    // const { position, isDragging, x, y, style } = useDraggable(refDataTableHead, {
    //   initialValue: { x: 40, y: 40 },
    //   onStart(position, event){
    //     console.log('headCell onStart', position, event, event.target)
    //     if (!(event && event.target)) {
    //       return false
    //     }
    //     const cell = (event.target as HTMLElement).closest('th')
    //     console.log('headCell onStart cell', cell, cell?.dataset)
    //     if (!(cell && cell.dataset.canMove === '1')) {
    //       return false
    //     }
    //     draggingCell.value = cell.dataset.fieldname || ''
    //   },
    //   onMove(position, event){
    //     const cell = (event.target as HTMLElement).closest('th')
    //     console.log('headCell onMove', cell?.dataset?.fieldname, position, cell?.getBoundingClientRect(), props.columns)
    //   }
    // })
    //
    // watch(isDragging, value => {
    //   if (!value) {
    //     draggingCell.value = ''
    //   }
    // })

    const onShowHeadTooltip = (fieldname) => {
      if (!refDataTableHead.value) {
        return false
      }
      const reference = refDataTableHead.value.querySelector(`th[data-fieldname="${fieldname}"] .grid-table-head-in span`) as HTMLElement
      return reference && reference.scrollWidth - reference.offsetWidth > 0
    }

    return {
      // position, isDragging, x, y, style,
      refDataTableContainer,
      tableClass: attrs.class,
      refMoveResizeColumnHint,
      mousedownHandler,
      mousemoveHandler,
      mouseupHandler,
      isMovingColumn,
      isResizingColumn,
      refDataTableHead,
      refMovingPreview,
      draggingCell,
      dragTargetCell,
      t,
      fieldsModel,
      // canMoveFieldsModel,
      // cannotMoveFieldsModel,
      minDarkColor,
      clickItemRowHandler,
      //headCellMousedownHandler,
      headCellClickHandler,
      columnsOrderChanged,
      //columnResizeStart,
      //columnResizeDrag,
      //columnResizeEnd,
      tableHeadContextMenuHandler,
      onShowHeadTooltip,
    }
  }
})
